import { ORDERS_LOADING, ORDERS_ERROR, GET_ORDERS } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  userId: undefined,
};

const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ORDERS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ORDERS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ORDERS, (state, { payload }) => {
      state.data = payload?.orders;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    });
});

export default orderReducer;
