import {
  PRODUCTS_LOADING,
  PRODUCTS_ERROR,
  GET_PRODUCTS,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const productsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PRODUCTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PRODUCTS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_PRODUCTS, (state, { payload }) => {
      state.data = payload?.products;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_PRODUCT, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(EDIT_PRODUCT, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
      state.error = false;
    });
});

export default productsReducer;
