import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../Layout/Navbar";
import PageLoader from "../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { appConfigAction } from "./../store/actions/app/index";

const Dashboard = lazy(() => import("../Pages/dashboard/index"));
const Login = lazy(() => import("../Pages/Login/index"));

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  const dispatch = useDispatch();
  const appLoading = useSelector((state) => state.app.loading);

  useEffect(() => {
    dispatch(appConfigAction());
  }, [dispatch]);

  if (appLoading) return <PageLoader />;

  return (
    <>
      {!location.pathname.includes("login") && <Navbar />}
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<PageLoader />}>
              <Dashboard />
            </Suspense>
          }
        />

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

export default Router;
