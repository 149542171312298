import {
  CONTACTS_LOADING,
  CONTACTS_ERROR,
  GET_CONTACTS,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const contactReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CONTACTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CONTACTS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_CONTACTS, (state, { payload }) => {
      state.data = payload?.contact;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    });
});

export default contactReducer;
